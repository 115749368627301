<div class="color-secondary-grays-{{ graysLevel }}">
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.254 3.07343H23.7405C23.3559 3.07343 23.0442 3.38515 23.0442 3.76968V32.5942C23.0442 32.9787 23.3559 33.2904 23.7405 33.2904H25.254C25.6385 33.2904 25.9503 32.9787 25.9503 32.5942V3.76968C25.9503 3.38515 25.6385 3.07343 25.254 3.07343Z"
      fill="#433197"
    />
    <path
      d="M21.1259 3.07343H19.6123C19.2278 3.07343 18.9161 3.38515 18.9161 3.76968V32.5942C18.9161 32.9787 19.2278 33.2904 19.6123 33.2904H21.1259C21.5104 33.2904 21.8221 32.9787 21.8221 32.5942V3.76968C21.8221 3.38515 21.5104 3.07343 21.1259 3.07343Z"
      fill="#433197"
    />
    <path
      d="M16.9978 3.07343H15.4842C15.0997 3.07343 14.788 3.38515 14.788 3.76968V32.5942C14.788 32.9787 15.0997 33.2904 15.4842 33.2904H16.9978C17.3823 33.2904 17.694 32.9787 17.694 32.5942V3.76968C17.694 3.38515 17.3823 3.07343 16.9978 3.07343Z"
      fill="#433197"
    />
    <path
      d="M12.87 3.07343H11.3565C10.9719 3.07343 10.6602 3.38515 10.6602 3.76968V32.5942C10.6602 32.9787 10.9719 33.2904 11.3565 33.2904H12.87C13.2545 33.2904 13.5663 32.9787 13.5663 32.5942V3.76968C13.5663 3.38515 13.2545 3.07343 12.87 3.07343Z"
      fill="#433197"
    />
    <path
      d="M8.74188 3.07343H7.22834C6.84381 3.07343 6.53209 3.38515 6.53209 3.76968V32.5942C6.53209 32.9787 6.84381 33.2904 7.22834 33.2904H8.74188C9.12641 33.2904 9.43813 32.9787 9.43813 32.5942V3.76968C9.43813 3.38515 9.12641 3.07343 8.74188 3.07343Z"
      fill="#433197"
    />
    <path
      d="M29.1089 3.08601L27.6056 3.26181C27.2237 3.30647 26.9503 3.6523 26.9949 4.03422L30.3429 32.6636C30.3876 33.0456 30.7334 33.319 31.1153 33.2743L32.6186 33.0985C33.0006 33.0539 33.274 32.708 33.2293 32.3261L29.8813 3.69669C29.8367 3.31476 29.4908 3.04135 29.1089 3.08601Z"
      fill="#433197"
    />
    <path
      d="M4.61375 3.07343H3.10022C2.71569 3.07343 2.40396 3.38515 2.40396 3.76968V32.5942C2.40396 32.9787 2.71569 33.2904 3.10022 33.2904H4.61375C4.99828 33.2904 5.31 32.9787 5.31 32.5942V3.76968C5.31 3.38515 4.99828 3.07343 4.61375 3.07343Z"
      fill="#433197"
    />
  </svg>
  @if (withTitle) {
  <h1>Cratebase</h1>
  }
</div>
